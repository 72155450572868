import { createApp } from 'vue'
import VueGtag from 'vue-gtag';
import VueCookies from 'vue3-cookies'
import VueTour from 'v3-tour'
import ToastPlugin from 'vue-toast-notification';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import '@mdi/font/css/materialdesignicons.css'
import 'vue-toast-notification/dist/theme-bootstrap.css';
import "v3-tour/dist/vue-tour.css";

import App from './App.vue'
import router from './router'
import store from './store';
import http from './plugins/axios';

const app = createApp(App)

const gtagConfig = {
  appName: 'Tuneeca Flipbook',
  pageTrackerScreenviewEnabled: true,
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID
  },
  router
}

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
  },
})

const cookies = {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None"
}

const axiosConfig = {
  baseURL: process.env.VUE_APP_COMMERCE_API_URL
}

const toastConfig = {
  position: 'top-right'
}

app.use(router)
app.use(http, axiosConfig)
app.use(VueCookies, cookies);
app.use(vuetify)
app.use(VueGtag, gtagConfig)
app.use(store)
app.use(ToastPlugin, toastConfig)
app.use(VueTour)

app.mount('#app')
